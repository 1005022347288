<template>
  <div>
    <vue-element-loading :active="appLoading" :is-full-screen="true" />
    <v-snackbar v-model="showsnackbar" color="#00a972" right :timeout="timeout">
      {{msg}}
      <v-btn text @click="showsnackbar = false">
        <v-icon style="color:white;">mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <v-layout pt-6 px-4 wrap justify-center>
      <v-flex xs12 sm12 md8 style="min-width:50vw;">
        <v-layout wrap px-2>
          <v-flex xs12 sm12 md12 text-left>
            <span style="font-size:22px;font-weight:600;">Blog</span>
          </v-flex>
        </v-layout>
        <v-layout wrap px-2>
          <v-flex v-if="blogData.type=='Normal Blog'">
            <v-img max-height="400px" :src="baseURL+'/u/'+blogData.image" contain></v-img>
          </v-flex>
          <v-flex v-else>
            <video width="100%" height="365" controls>
              <source :src="baseURL+'/u/'+blogData.image" type="video/mp4" />
            </video>
          </v-flex>
        </v-layout>
        <v-layout wrap>
          <v-flex xs12 sm12 md12 px-4 pt-2>
            <span style="font-size:20px;font-weight:600;">{{blogData.title}}</span>
          </v-flex>
          <v-flex xs12 sm12 md12 px-4 pt-2 text-justify>
            <span style="font-size:18px;font-weight:400">{{blogData.shortDescription}}</span>
          </v-flex>
        </v-layout>
        <v-layout wrap px-4 pt-2>
          <v-flex text-left>
            <span style="font-size:16px;font-weight:400;color:#1fb07c">{{blogData.author}}</span>
          </v-flex>
          <v-flex text-left>
            <span style="font-size:16px;font-weight:400;color:#1fb07c">{{blogData.createddate}}</span>
          </v-flex>
          <!-- <v-flex text-left>
            <span style="font-size:16px;color:#1fb07c">
              <v-icon color="#1fb07c">mdi-heart-outline</v-icon>
            </span>
            <span style="font-size:16px;color:#1fb07c">
              <v-icon>mdi-bookmark-outline</v-icon>
            </span>
          </v-flex>-->
        </v-layout>
        <v-layout wrap px-4 pt-2>
          <v-flex text-justify>
            <span style="font-size:16px;font-weight:400" v-html="blogData.body"></span>
          </v-flex>
        </v-layout>

        <v-layout pt-2 wrap justify-center px-4>
          <v-flex>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
        <v-layout wrap py-1 px-4>
          <v-flex xs2 sm2 md2>
            <span style="font-size:12px;font-Weight:500;">Tags:&nbsp;</span>
          </v-flex>
          <v-flex xs2 sm2 md2 text-left v-for="(item, i) in blogData.tag" :key="i">
            <v-chip class="mx-1" x-small>{{item}}</v-chip>
          </v-flex>
        </v-layout>

        <v-layout wrap py-1 px-4>
          <v-flex xs2 sm2 md2>
            <span style="font-size:12px;font-Weight:500;">Categories:&nbsp;</span>
          </v-flex>
          <v-flex xs2 sm2 md2 text-left v-for="(item, i) in cat" :key="i">
            <v-chip class="mx-1" x-small>{{item}}</v-chip>
          </v-flex>
        </v-layout>
        <v-layout pt-2 wrap justify-center px-4>
          <v-flex>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import VueElementLoading from "vue-element-loading";
export default {
  props: ["id"],
  components: {
    VueElementLoading
  },
  data() {
    return {
      appLoading: false,
      blogData: null,
      showsnackbar: false,
      timeout: 5000,
      categories: [],
      cat: [],
      msg: null
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    getCategory() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/project/category/getlist"
      })
        .then(response => {
          this.appLoading = false;
          this.categories = response.data.data;
          for (var i = 0; i < this.blogData.category.length; i++) {
            for (var j = 0; j < this.categories.length; j++) {
              if (this.blogData.category[i] == this.categories[j]._id) {
                this.blogData.category[i] = this.categories[j].name;
              }
            }
          }
          this.cat = this.blogData.category;
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/blog/get",
        params: {
          id: this.id
        }
      })
        .then(response => {
          this.appLoading = false;
          this.blogData = response.data.data;
          var dt = new Date(this.blogData.createddate);
          var day = dt.getDate();
          var year = dt.getFullYear();
          var hours = dt.getHours();
          var minutes = dt.getMinutes();
          dt = dt.toString();
          var ampm = hours >= 12 ? "pm" : "am";
          hours = hours % 12;
          hours = hours ? hours : 12;
          minutes = minutes < 10 ? "0" + minutes : minutes;
          var strTime =
            day +
            " " +
            dt.slice(4, 7) +
            " " +
            year +
            " " +
            hours +
            ":" +
            minutes +
            " " +
            ampm;

          this.blogData.createddate = strTime;
          this.getCategory();
        })
        .catch(err => {
          this.appLoading = false;
          this.msg = err;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    }
  }
};
</script>